<template>
  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[78vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="text-center w-full">
          <div class="relative inline-block mb-5">
            <button
              id="dropdownDefaultButton"
              data-dropdown-toggle="dropdown"
              class="text-white bg-[#227B9B] focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center uppercase"
              type="button"
              @click="showSelect = !showSelect"
            >
              <img :src="selectedCurrency?.flag" class="w-[32px] mr-1" alt="" /> |
              {{ selectedCurrency?.name }}
              <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <div
              v-if="showSelect"
              id="dropdown"
              class="absolute left-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li v-for="item in currencies" :key="item.name">
                  <a
                    href="#"
                    @click.prevent="updateCurrency(item.name)"
                    class="flex items-center uppercase px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    ><img :src="item.flag" class="w-[32px] mr-1" alt="" /> | {{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <h1 class="text-[60px] font-semibold text-brand-black">Pick the right plan for you</h1>
          <p class="text-brand-black text-[27px] mb-8">
            It’s completely free to get started. We only charge a small <br />
            transaction fee for every sale you make on any of our plans.
          </p>

          <div class="flex justify-center">
            <div class="flex bg-brand-black rounded gap-x-2 p-1 px-2">
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="radio" v-model="period" value="monthly" name="period" class="sr-only peer" />
                <div
                  class="peer peer-checked:bg-white text-center peer-checked:drop-shadow-xl text-white text-[14px] peer-checked:text-brand-black w-full rounded p-2 font-medium"
                >
                  Monthly
                </div>
              </label>
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="radio" v-model="period" value="quaterly" name="period" class="sr-only peer" />
                <div
                  class="peer peer-checked:bg-white text-center peer-checked:drop-shadow-xl text-white text-[14px] peer-checked:text-brand-black w-full rounded p-2 font-medium"
                >
                  Quaterly
                </div>
              </label>
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="radio" v-model="period" value="biannually" name="period" class="sr-only peer" />
                <div
                  class="peer peer-checked:bg-white text-center peer-checked:drop-shadow-xl text-white text-[14px] peer-checked:text-brand-black w-full rounded p-2 font-medium"
                >
                  Biannually
                </div>
              </label>
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="radio" v-model="period" value="yearly" name="period" class="sr-only peer" />
                <div
                  class="peer peer-checked:bg-white text-center peer-checked:drop-shadow-xl text-white text-[14px] peer-checked:text-brand-black w-full rounded p-2 font-medium"
                >
                  Yearly
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-brand-black">
    <div class="w-full lg:w-[78vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="md:grid grid-cols-3 gap-10 pb-5">
          <pricing-card
            v-for="(item, index) in subscriptions"
            :key="item.uuid"
            :subscription="item"
            :period="period"
            :currency="currency || settings?.app_base_currency"
            :custom-class="(index + 1) % 2 == 0 ? 'text-white bg-[#227B9B]' : 'bg-[#FFFFFF]'"
          />
        </div>
      </div>
    </div>
  </div>

  <Customers />

  <faqs :faqs="faqs" :bg-class="'bg-light-yellow'" :text-class="'text-brand-black'" />

  <div class="flex justify-center bg-brand-black">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[8rem] text-white text-center">
        <h1 class="text-white text-[32px] md:text-[60px] leading-[110%] font-semibold">Subscribe to our newsletter</h1>

        <p class="text-[18px] md:text-[25px] leading-[110%] mb-8 text-white/80">
          Get to know about top open jobs in Africa’s fastest-growing startups before anyone else. Every week, <br class="hidden md:inline-block" />
          we'll send you all the top jobs in startups across Africa.
        </p>
        <br />
        <form class="block md:flex justify-center items-center">
          <input type="email" class="py-4 px-8 border rounded-xl mr-4 w-full md:w-[400px] mb-6 md:mb-0" placeholder="Enter your email address" />
          <button class="rounded-xl px-8 md:px-16 py-4 text-center bg-white text-brand-black text-[15px] md:text-[18px] w-full md:w-auto">
            Subscribe
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import PricingCard from '@/components/subscriptions/PricingCard.vue';
import Customers from '@/components/partials/Customers.vue';
import IconNgn from '@/assets/images/icons-ngn.png';
import IconUsa from '@/assets/images/icons-usa.png';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Faqs from '@/components/partials/Faqs.vue';
import { useMeta } from '@/utils/meta';

const faqs = ref([
  {
    title: 'Which plan should I choose?',
    desc: `<p>Content is yet to be uploaded for this Question</p>`,
  },
  {
    title: 'When will I get charged?',
    desc: `<p>Content is yet to be uploaded for this Question</p>`,
  },
  {
    title: 'Can I ask for a refund?',
    desc: `<p>Content is yet to be uploaded for this Question</p>`,
  },
  {
    title: 'What payment methods do you offer?',
    desc: `<p>Content is yet to be uploaded for this Question</p>`,
  },
  {
    title: 'What is your refund policy?',
    desc: `<p>Content is yet to be uploaded for this Question</p>`,
  },
]);

const store = useStore();
const period = ref('monthly');
const showSelect = ref(false);
const currency = ref('');
const currencies = ref({
  usd: {
    name: 'usd',
    flag: IconUsa,
  },
  ngn: {
    name: 'ngn',
    flag: IconNgn,
  },
});

const selectedCurrency = computed(() => {
  const setting = store.getters['getSettings'];
  const selected = currency.value || setting?.app_base_currency;
  return currencies.value[selected];
});

const subscriptions = computed(() => {
  const rate = currency.value == 'ngn' ? Object.values(settings.value?.conversion_rates)[0] : 1;
  console.log(rate);
  switch (period.value) {
    case 'quaterly':
      return store.getters['getPlans'].map((item) => ({
        ...item,
        amount: item.amount * 4 * rate,
      }));
    case 'biannually':
      return store.getters['getPlans'].map((item) => ({
        ...item,
        amount: item.amount * 6 * rate,
      }));
    case 'yearly':
      return store.getters['getPlans'].map((item) => ({
        ...item,
        amount: item.amount * 12 * rate,
      }));
    default:
      return store.getters['getPlans'].map((item) => ({
        ...item,
        amount: item.amount * rate,
      }));
  }
});

const settings = computed(() => {
  return store.getters['getSettings'];
});

const updateCurrency = (value) => {
  currency.value = value;
  showSelect.value = false;
};

const getSubs = async () => {
  //scroll to top of list
  try {
    await store.dispatch('getSubscriptionPlans');
  } catch (error) {
    console.log(error);
  }
};

const getSettings = async () => {
  //scroll to top of list
  try {
    await store.dispatch('getSettings');
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  await Promise.all([getSubs(), getSettings()]);
});

useMeta();
</script>

<template>
  <div :class="['flex justify-center', bgClass]">
    <div class="w-full px-[24px] md:px-0 md:w-[65vw] flex items-center justify-center">
      <div class="py-[5rem] w-full">
        <h2 :class="['text-center mb-8 text-[32px] md:text-[60px] leading-[110%] font-semibold', textClass]">Frequently Asked Question</h2>

        <div class="bg-white p-8 rounded-xl w-full">
          <div class="mb-6" v-for="(faq, index) in faqs" :key="index">
            <div
              class="flex justify-between items-center cursor-pointer"
              @click="selectedFaq == index ? (selectedFaq = null) : (selectedFaq = index)"
            >
              <h2 class="text-[20px] font-medium">{{ faq.title }}</h2>

              <img src="@/assets/icons/chevron-up.svg" v-if="selectedFaq == index" alt="" />
              <img src="@/assets/icons/plus.svg" v-else alt="" />
            </div>
            <div v-html="faq.desc" class="pt-6 pr-8 faq-desc" v-if="selectedFaq == index"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps({
  faqs: { type: Array, default: [] },
  bgClass: { type: String, default: 'bg-brand-black' },
  textClass: { type: String, default: 'text-white' },
});

const selectedFaq = ref(null);
</script>

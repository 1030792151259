<template>
  <div class="bg-white m-0 rounded-xl w-full mb-4 md:mb-0">
    <div :class="[customClass, 'px-6 py-3 border-b rounded-t-xl']">
      <h4 class="text-[22px] font-normal capitalize mb-1 font-semibold">
        {{ subscription?.name }}
      </h4>
      <p class="text-[#4D4D4D] text-[16px] font-medium mb-6" v-if="subscription?.description">
        {{ subscription?.description }}
      </p>
    </div>
    <div class="my-4 p-6">
      <a
        :href="`${employer_url}/subscription-plan/${subscription?.uuid}`"
        class="block text-center py-4 text-white text-[16px] bg-brand-black rounded-xl font-medium"
        >Get Started</a
      >
    </div>
    <div class="px-6 mb-6">
      <h1 class="text-[28px] font-semibold">
        {{ currencyCodes[currency || settings?.app_base_currency] }}
        {{ formatPricing(subscription?.amount) }}<small class="text-[28px] font-medium text-[#000000]/[.6]">/{{ periodText }}</small>
      </h1>
    </div>
    <div class="mb-8 px-6">
      <feature v-for="(item, index) in features.slice(0, 5)" :key="index" :title="item" />
    </div>
  </div>
</template>

<script setup>
import Feature from '@/components/subscriptions/Feature.vue';
import { ref, computed } from 'vue';

const employer_url = ref(process.env.VUE_APP_EMPLOYER_URL);
const currencyCodes = ref({
  usd: '$',
  ngn: '₦',
});

const props = defineProps({
  subscription: { type: Object, default: {} },
  currency: { type: String, default: '' },
  period: { type: String, default: '' },
  customClass: { type: String, default: 'bg-[#FFFFFF]' },
});

const periodText = computed(() => {
  const text = props.period.split('');
  return text.slice(0, text.length - 2).join('');
});

const formatPricing = (amount) => {
  return parseFloat(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const features = computed(() => {
  return props.subscription.features || [];
});
</script>
